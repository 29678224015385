// import image from '../../images/R1el9GtltHk.jpg';
// import dog10 from '../../images/DogsPhoto/Dog10 1.jpg';
// import dog11 from '../../images/DogsPhoto/Dog11 gallery.jpg';
// import dog12 from '../../images/DogsPhoto/Dog12 gallery.jpg';
// import dog13 from '../../images/DogsPhoto/Dog13 galery.jpg';
import dog1 from '../../images/DogsPhoto/Dana1.jpg';
import dog2 from '../../images/DogsPhoto/Chapa2.jpg';
import dog3 from '../../images/DogsPhoto/Lord3.jpg';
import dog4 from '../../images/DogsPhoto/Tera4.jpg';
import dog5 from '../../images/DogsPhoto/Sevara5.jpg';
import dog6 from '../../images/DogsPhoto/Pizhuma6.jpg';
import dog7 from '../../images/DogsPhoto/Liska7.jpg';
import dog8 from '../../images/DogsPhoto/Carmen8.jpg';
import dog9 from '../../images/DogsPhoto/Viuga9.jpg';
import dog10 from '../../images/DogsPhoto/Hurma10.jpg';
import dog11 from '../../images/DogsPhoto/Lavr11.jpg';
import dog12 from '../../images/DogsPhoto/Naida12.jpg';
// import dog13 from '../../images/DogsPhoto/13.jpg';
// import dog14 from '../../images/DogsPhoto/14.jpg';
// import dog15 from '../../images/DogsPhoto/15.jpg';
// import dog16 from '../../images/DogsPhoto/16.jpg';
// import dog17 from '../../images/DogsPhoto/17.jpg';
// import dog18 from '../../images/DogsPhoto/18.jpg';
// import dog19 from '../../images/DogsPhoto/19.jpg';
// import dog20 from '../../images/DogsPhoto/20.jpg';
// import dog21 from '../../images/DogsPhoto/21.jpg';
// import dog22 from '../../images/DogsPhoto/22.jpg';
// import dog23 from '../../images/DogsPhoto/23.jpg';
// import dog24 from '../../images/DogsPhoto/24.jpg';
// import dog25 from '../../images/DogsPhoto/25.jpg';
// import dog26 from '../../images/DogsPhoto/26.jpg';
// import dog27 from '../../images/DogsPhoto/27.jpg';
// import dog28 from '../../images/DogsPhoto/28.jpg';
// import dog29 from '../../images/DogsPhoto/29.jpg';
// import dog30 from '../../images/DogsPhoto/30.jpg';
// import dog31 from '../../images/DogsPhoto/31.jpg';
// import dog32 from '../../images/DogsPhoto/32.jpg';
// import dog33 from '../../images/DogsPhoto/33.jpg';
// import dog34 from '../../images/DogsPhoto/34.jpg';
// import dog35 from '../../images/DogsPhoto/35.jpg';
// import dog36 from '../../images/DogsPhoto/36.jpg';
// import dog37 from '../../images/DogsPhoto/37.jpg';
// import dog38 from '../../images/DogsPhoto/38.jpg';
// import dog39 from '../../images/DogsPhoto/39.jpg';
// import dog40 from '../../images/DogsPhoto/40.jpg';
// import dog41 from '../../images/DogsPhoto/41.jpg';
// import dog42 from '../../images/DogsPhoto/42.jpg';
// import dog43 from '../../images/DogsPhoto/43.jpg';
// import dog44 from '../../images/DogsPhoto/44.jpg';
// import dog45 from '../../images/DogsPhoto/45.jpg';
// import dog46 from '../../images/DogsPhoto/46.jpg';
// import dog47 from '../../images/DogsPhoto/47.jpg';
// import dog48 from '../../images/DogsPhoto/48.jpg';
// import dog49 from '../../images/DogsPhoto/49.jpg';
// import dog50 from '../../images/DogsPhoto/50.jpg';
// import dog51 from '../../images/DogsPhoto/51.jpg';
// import dog52 from '../../images/DogsPhoto/52.jpg';
// import dog53 from '../../images/DogsPhoto/53.jpg';
// import dog54 from '../../images/DogsPhoto/54.jpg';
// import dog55 from '../../images/DogsPhoto/55.jpg';
// import dog56 from '../../images/DogsPhoto/56.jpg';
// import dog57 from '../../images/DogsPhoto/57.jpg';
// import dog58 from '../../images/DogsPhoto/58.jpg';
// import dog59 from '../../images/DogsPhoto/59.jpg';
// import dog60 from '../../images/DogsPhoto/60.jpg';

const dogsData = [
  {
    id: 1,
    name: "Дана",
    link: dog1,
    description: "Дана - умная, хитрая лисичка, звоночек. Обработана, вакцинирована, стерилизована.",
  },
  {
    id: 2,
    name: "Чапа",
    link: dog2,
    description: "Кобель, метис, 4 года. Очень активный, умный, знает машину, хорошо ходит на поводке, знает команды. Не любит кошек. Кастрирован.",
  },
  {
    id: 3,
    name: "Лорд",
    link: dog3,
    description: "Лорду два года. Скромный, добрый парень, играет тихонько. Кастрирован.",
    postSocial: "https://vk.com/wall-113938062_29422"
  },
  {
    id: 4,
    name: "Терра",
    link: dog4,
    description: "Терре около 4 лет, она ласковая, нежная и хорошенькая. От своего человека уже не хочет отходить ни на шаг.",
    postSocial: "https://vk.com/wall-113938062_29382"
  },
  {
    id: 5,
    name: "Севара",
    link: dog5,
    description: "Севара - сестрица Пыжумы, у нее нежный, скромный и ласковый характер.",
    postSocial: "https://vk.com/wall-113938062_29354"
  },
  {
    id: 6,
    name: "Пыжума",
    link: dog6,
    description: "Пыжуме 1 год, Стерилизованная, аккуратная, скромная, любящая ласку, добрая к домашним, грозно облаивает чужих.",
    postSocial: "https://vk.com/wall-113938062_29346"
  },
  {
    id: 7,
    name: "Лиска",
    link: dog7,
    description: "Лиска среднего размера, дружелюбная, стерилизованная.",
    postSocial: "https://vk.com/wall-113938062_29294"
  },
  {
    id: 8,
    name: "Кармен",
    link: dog8,
    description: "Девочка, стерилизованна, 1 год. Веселая, жизнерадостная, среднего размера собачка.",
    postSocial: "https://vk.com/wall-113938062_29291"
  },
  {
    id: 9,
    name: "Вьюга",
    link: dog9,
    description: "Девочка, около 4 месяцев, Скучает, иногда заводит грустные песни.",
    postSocial: "https://vk.com/wall-113938062_29280"
  },
  {
    id: 10,
    name: "Хурма",
    link: dog10,
    description: "Активная, весёлая собачка, 6 месяцев. Будет среднего размера.",
    postSocial: "https://vk.com/wall-113938062_31488"
  },
  {
    id: 11,
    name: "Лавр",
    link: dog11,
    description: "Лавр. Фенотип лабрадора. Спокойный, но умеет охранять.",
    postSocial: "https://vk.com/wall-113938062_31148"
  },
  {
    id: 12,
    name: "Найда",
    link: dog12,
    description: "Найда! 8 лет. Небольшая, тихая, скромная, ласковая собачка.",
    postSocial: "https://vk.com/wall-113938062_31606"
  },

  // {
  //   id: 2,
  //   name: "Пыжуму",
  //   link: "https://drive.google.com/uc?export=view&id=1juAf251BRqB77HGV8LBNcv9S5SNTBo6H",
  //   description: "Пыжума очень скромная, спокойная и дружелюбная собака!",
  // },
  // {
  //   id: 3,
  //   name: "Федя",
  //   link: dog3,
  //   description: "Феде больше десяти лет, он слеповат, но лидерские качества не утратил!",
  // },
  // {
  //   id: 4,
  //   name: "Гами",
  //   link: dog4,
  //   description: "Ему один год, вакцинирован от вирусных заболеваний кастрирован. Очень добродушный и общительный",
  // },
  // {
  //   id: 5,
  //   name: "Tolik",
  //   link: dog5,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 6,
  //   name: "Tolik",
  //   link: dog6,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 7,
  //   name: "Tolik",
  //   link: dog7,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 8,
  //   name: "Tolik",
  //   link: dog8,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 9,
  //   name: "Tolik",
  //   link: dog9,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 10,
  //   name: "Tolik",
  //   link: dog10,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 11,
  //   name: "Tolik",
  //   link: dog11,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 12,
  //   name: "Tolik",
  //   link: dog12,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 13,
  //   name: "Tolik",
  //   link: dog13,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 14,
  //   name: "Tolik",
  //   link: dog14,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 15,
  //   name: "Tolik",
  //   link: dog15,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 16,
  //   name: "Tolik",
  //   link: dog16,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 17,
  //   name: "Tolik",
  //   link: dog17,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 18,
  //   name: "Tolik",
  //   link: dog18,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 19,
  //   name: "Tolik",
  //   link: dog19,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 20,
  //   name: "Tolik",
  //   link: dog20,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 21,
  //   name: "Tolik",
  //   link: dog21,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 22,
  //   name: "Tolik",
  //   link: dog22,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 23,
  //   name: "Tolik",
  //   link: dog23,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 24,
  //   name: "Tolik",
  //   link: dog24,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 25,
  //   name: "Tolik",
  //   link: dog25,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 26,
  //   name: "Tolik",
  //   link: dog26,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 27,
  //   name: "Tolik",
  //   link: dog27,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 28,
  //   name: "Tolik",
  //   link: dog28,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 29,
  //   name: "Tolik",
  //   link: dog29,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 30,
  //   name: "Tolik",
  //   link: dog30,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 31,
  //   name: "Tolik",
  //   link: dog31,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 32,
  //   name: "Tolik",
  //   link: dog32,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 33,
  //   name: "Tolik",
  //   link: dog33,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 34,
  //   name: "Tolik",
  //   link: dog34,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 35,
  //   name: "Tolik",
  //   link: dog35,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 36,
  //   name: "Tolik",
  //   link: dog36,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 37,
  //   name: "Tolik",
  //   link: dog37,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 38,
  //   name: "Tolik",
  //   link: dog38,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 39,
  //   name: "Tolik",
  //   link: dog39,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 40,
  //   name: "Tolik",
  //   link: dog40,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 41,
  //   name: "Tolik",
  //   link: dog41,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 42,
  //   name: "Tolik",
  //   link: dog42,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 43,
  //   name: "Tolik",
  //   link: dog43,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 44,
  //   name: "Tolik",
  //   link: dog44,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 45,
  //   name: "Tolik",
  //   link: dog46,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 47,
  //   name: "Tolik",
  //   link: dog47,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 48,
  //   name: "Tolik",
  //   link: dog48,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 49,
  //   name: "Tolik",
  //   link: dog49,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 50,
  //   name: "Tolik",
  //   link: dog50,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 51,
  //   name: "Tolik",
  //   link: dog51,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 52,
  //   name: "Tolik",
  //   link: dog52,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 53,
  //   name: "Tolik",
  //   link: dog53,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 54,
  //   name: "Tolik",
  //   link: dog54,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 55,
  //   name: "Tolik",
  //   link: dog55,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 56,
  //   name: "Tolik",
  //   link: dog56,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 57,
  //   name: "Tolik",
  //   link: dog57,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 58,
  //   name: "Tolik",
  //   link: dog58,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 59,
  //   name: "Tolik",
  //   link: dog59,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },
  // {
  //   id: 60,
  //   name: "Tolik",
  //   link: dog60,
  //   description: "Люблю бегать, прыгать, лаять, копать землю и искать всякие палки",
  // },


  


];

export default dogsData;